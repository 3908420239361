
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.sticky {
  .sticky-placeholder {
    display: none;
  }

  &.is-sticky {
    .sticky-placeholder {
      display: block;
    }

    .sticky-container {
      position: fixed;
      z-index: $zindex-sticky;
    }
  }
}
