
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$search-results-noresults-padding-y: $component-padding-y !default;
$search-results-noresults-padding-x: 0 !default;
$search-results-noresults-text-align: center !default;

$search-introcontent-gap: $grid-gutter-width !default;
$search-outrocontent-gap: 0 !default;
$search-outrocontent-font-weight: $font-weight-bold !default;
$search-results-gap: $grid-gutter-width * 0.5 !default;
$search-loadmore-gap: $spacer !default;
$search-scrolltop-gap: $grid-gutter-width !default;

// desktop

$search-desktop: $desktop-breakpoint !default;

$search-desktop-categorylist-item-gap: $spacer * 1.5 !default;
$search-desktop-categorylist-sub-item-margin: $spacer !default;
$search-desktop-categorylist-item-padding-y: $spacer * 0.25 !default;
$search-desktop-categorylist-item-padding-x: $spacer * 0.8 !default;
$search-desktop-categorylist-item-border: set-nth(map-get($borders, 'bold'), 3, transparent) !default;
$search-desktop-categorylist-item-font-size: inherit !default;
$search-desktop-categorylist-sub-item-font-size: 0.95em !default;
$search-desktop-categorylist-item-font-weight: $font-weight-base !default;
$search-desktop-categorylist-item-color: inherit !default;
$search-desktop-categorylist-item-line-height: 1.2 !default;

$search-desktop-categorylist-item-hover-border: set-nth($search-desktop-categorylist-item-border, 3, $primary) !default;
$search-desktop-categorylist-item-hover-font-size: $search-desktop-categorylist-item-font-size !default;
$search-desktop-categorylist-item-hover-font-weight: $font-weight-bold !default;
$search-desktop-categorylist-item-hover-color: $search-desktop-categorylist-item-color !default;

$search-desktop-categorylist-item-active-border: $search-desktop-categorylist-item-hover-border !default;
$search-desktop-categorylist-item-active-font-size: $search-desktop-categorylist-item-hover-font-size !default;
$search-desktop-categorylist-item-active-font-weight: $search-desktop-categorylist-item-hover-font-weight !default;
$search-desktop-categorylist-item-active-color: $search-desktop-categorylist-item-hover-color !default;

$search-desktop-results-noresults-padding-y: $component-desktop-padding-y !default;
$search-desktop-results-noresults-padding-x: 0 !default;
$search-desktop-results-noresults-text-align: $search-results-noresults-text-align !default;

$search-desktop-introcontent-gap: $grid-gutter-width !default;
$search-desktop-outrocontent-gap: $search-desktop-introcontent-gap !default;
$search-desktop-results-gap: $grid-gutter-width !default;
$search-desktop-loadmore-gap: $spacer !default;
$search-desktop-scrolltop-gap: $grid-gutter-width !default;

.search {
  @include media-breakpoint-down(get-breakpoint-key($search-desktop, -1)) {
    .search-categorylist {
      @include sr-only();
    }

    .search-introcontent {
      margin-bottom: $search-introcontent-gap;
    }

    .search-results {
      margin-top: $search-results-gap;
      margin-bottom: $search-results-gap;

      .results-noresuls {
        padding: $search-results-noresults-padding-y $search-results-noresults-padding-x;
        text-align: $search-results-noresults-text-align;
      }
    }

    .search-loadmore {
      margin-bottom: $search-loadmore-gap;
      text-align: center;

      .btn {
        margin-top: $search-loadmore-gap;
      }
    }

    .search-scrolltop {
      margin-bottom: $search-scrolltop-gap;
      text-align: right;
    }

    .search-outrocontent {
      margin-bottom: $search-outrocontent-gap;
    }
  }

  @include media-breakpoint-up($search-desktop) {
    .search-categorylist {
      margin-bottom: $search-desktop-categorylist-item-gap;

      ul {
        @include list-unstyled();

        > li {
          padding: $search-desktop-categorylist-item-padding-y 0;

          > a {
            display: inline-block;
            padding: 0 $search-desktop-categorylist-item-padding-x;
            border-left: $search-desktop-categorylist-item-border;
            font-size: $search-desktop-categorylist-item-font-size;
            font-weight: $search-desktop-categorylist-item-font-weight;
            color: $search-desktop-categorylist-item-color;
            line-height: $search-desktop-categorylist-item-line-height;
            text-decoration: none;

            &:hover {
              border-left: $search-desktop-categorylist-item-hover-border;
              font-size: $search-desktop-categorylist-item-hover-font-size;
              font-weight: $search-desktop-categorylist-item-hover-font-weight;
              color: $search-desktop-categorylist-item-hover-color;
            }
          }

          &.active {
            > a {

              &,
              &:hover {
                border-left: $search-desktop-categorylist-item-active-border;
                font-size: $search-desktop-categorylist-item-active-font-size;
                font-weight: $search-desktop-categorylist-item-active-font-weight;
                color: $search-desktop-categorylist-item-active-color;
              }
            }

            > a:is(:last-child) {
              border-left: $search-desktop-categorylist-item-active-border;
            }
          }

          ul {
            margin-left: $search-desktop-categorylist-sub-item-margin;
            font-size: $search-desktop-categorylist-sub-item-font-size;
          }
        }
      }
    }

    .search-introcontent {
      margin-bottom: $search-desktop-introcontent-gap;
    }

    .search-results {
      margin-top: $search-desktop-results-gap;
      margin-bottom: $search-desktop-results-gap;

      .results-noresuls {
        padding: $search-desktop-results-noresults-padding-y $search-desktop-results-noresults-padding-x;
        text-align: $search-desktop-results-noresults-text-align;
      }
    }

    .search-loadmore {
      margin-bottom: $search-desktop-loadmore-gap;
    }

    .search-scrolltop {
      margin-bottom: $search-desktop-scrolltop-gap;
      text-align: right;
    }

    .search-outrocontent {
      margin-bottom: $search-desktop-outrocontent-gap;

      .category-content {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
          font-weight: $search-outrocontent-font-weight;
          font-size: inherit;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
