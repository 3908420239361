
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$searchfilterheader-padding-y: $spacer !default;
$searchfilterheader-padding-x: 0 !default;
$searchfilterheader-bg: $beige !default;

$searchfilterheader-filters-buttons-gap: $spacer !default;
$searchfilterheader-filters-buttons-active-gap: $spacer * 0.4 !default;

// desktop

$searchfilterheader-desktop: $desktop-breakpoint !default;

$searchfilterheader-desktop-entry-gap: $spacer * 0.25 !default;

$searchfilterheader-desktop-filters-buttons-active-gap: $spacer * 0.5 !default;
$searchfilterheader-desktop-filters-buttons-active-padding-y: $spacer * 0.75 !default;
$searchfilterheader-desktop-filters-buttons-active-padding-x: $searchfilterheader-desktop-filters-buttons-active-padding-y !default;
$searchfilterheader-desktop-filters-buttons-active-bg: $beige !default;
$searchfilterheader-desktop-filters-buttons-active-border-radius: $border-radius !default;
$searchfilterheader-desktop-filters-buttons-active-btn-gap: $spacer * 0.5 !default;
$searchfilterheader-desktop-filters-buttons-active-btn-line-height: 1.2 !default;
$searchfilterheader-desktop-filters-buttons-active-btn-icon-gap: $spacer * 0.5 !default;

.search-filterheader {
  @include media-breakpoint-down(get-breakpoint-key($searchfilterheader-desktop, -1)) {
    min-height: 83px;

    @include fullwidth-bg();
    padding: $searchfilterheader-padding-y $searchfilterheader-padding-x;
    background-color: $searchfilterheader-bg;

    .filterheader-filters {
      .filters-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: $searchfilterheader-filters-buttons-gap;

        .filters-active {
          @include fullwidth-mobile();
          overflow: hidden;
          margin-left: 0;
          margin-right: 0;
          max-width: 100%;

          .btn-list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;
            padding-left: $searchfilterheader-filters-buttons-active-gap;

            &:first-child {
              padding-left: 0;
            }

            .btn,
            .button,
            button {
              margin-top: 0;
              margin-bottom: 0;
              white-space: nowrap;
              box-shadow: none;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up($searchfilterheader-desktop) {
    .filterheader-entry {
      margin-bottom: $searchfilterheader-desktop-entry-gap;

      * {
        margin-bottom: 0;
      }
    }

    .filterheader-filters {
      .filters-buttons {
        .filters-toggle {
          display: none;
        }

        .filters-active {
          margin-bottom: $searchfilterheader-desktop-filters-buttons-active-gap;
          padding: $searchfilterheader-desktop-filters-buttons-active-padding-y $searchfilterheader-desktop-filters-buttons-active-padding-x;
          background-color: $searchfilterheader-desktop-filters-buttons-active-bg;
          border-radius: $searchfilterheader-desktop-filters-buttons-active-border-radius;

          .btn {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            line-height: $searchfilterheader-desktop-filters-buttons-active-btn-line-height;

            .bi {
              margin-left: $searchfilterheader-desktop-filters-buttons-active-btn-icon-gap;
            }
          }
        }
      }
    }
  }
}
