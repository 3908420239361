
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$searchfilter-colors-control-border-width: 1px !default;
$searchfilter-colors-control-border-color: $control !default;

.search-filter.filter-colors {
  .filter-control {
    .custom-control-input {
      ~ .custom-control-label {
        &:before,
        &:after {
          border-radius: 50%;
        }
      }

      &:not(:checked) {
        ~ .custom-control-label {
          &:before {
            border-width: $searchfilter-colors-control-border-width;
            border-color: $searchfilter-colors-control-border-color;
          }
        }
      }
    }
  }
}
