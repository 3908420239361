
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$searchresulttypeproduct-productteaser-padding-top: $spacer * 0.5 !default;
$searchresulttypeproduct-productteaser-border: $card-border-width solid $card-border-color !default;

// tablet

$searchresulttypeproduct-tablet: $tablet-breakpoint !default;

.search-resulttype-product {
  @include media-breakpoint-down(get-breakpoint-key($searchresulttypeproduct-tablet, -1)) {
    border-top: $searchresulttypeproduct-productteaser-border;

    .product-teaser {
      padding-top: $searchresulttypeproduct-productteaser-padding-top;

      .card {
        border-top: 0 none;
        border-left: 0 none;
        border-right: 0 none;
        border-radius: 0;

        .card-body,
        .card-footer {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  @include media-breakpoint-up($searchresulttypeproduct-tablet) {
    > .row {
      margin-top: $grid-gutter-width * -0.5;
      margin-bottom: $grid-gutter-width * -0.5;

      > .col {
        margin-top: $grid-gutter-width * 0.5;
        margin-bottom: $grid-gutter-width * 0.5;
      }
    }

    &.has-promobox {
      > .row {
        .col {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down($searchresulttypeproduct-tablet) {
    &.view-stacked {
      border-top: 0;

      .row {
        .col {
          flex: 0 0 100%;
          max-width: 100%;

          margin-top: 5px;
          margin-bottom: 5px;

          .product-teaser {
            .card {
              display: grid;
              grid-template-columns: 33vw 1fr;
              grid-auto-rows: auto auto;
              column-gap: 8px;

              border-top: none;
              border-left: none;
              border-right: none;
              border-radius: 0;

              .card-img {
                grid-row: 1 / 3;
                grid-column: 1 / 2;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              }

              .eyecatcher-stack {
                grid-row: 1 / 3;
                grid-column: 1 / 2;

                margin: 8px 8px 0;

                .eyecatcher.eyecatcher-sm {
                  width: 48px;
                  height: 48px;
                }
              }

              .card-body {
                grid-column: 2;

                padding-top: 0;
              }

              .card-footer {
                grid-column: 2;

                padding-bottom: 0;

                .product-wishlistbutton {
                  position: absolute;
                  bottom: 5px;
                  right: 5px;
                }
              }
            }
          }

          &:first-child {
            margin-top: 15px;
          }

          &:last-child {
            .product-teaser {
              .card {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
