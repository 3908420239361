
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

// tablet

$searchsorting-tablet: $tablet-breakpoint !default;
$searchsorting-tablet-label-gap: $spacer * 0.5 !default;

.search-sorting {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-down(get-breakpoint-key($searchsorting-tablet, -1)) {
    .sorting-label {
      display: none;
    }

    .sorting-dropdown {
      .dropdown-toggle {
        // background-position: center center!important;
      }

      .dropdown-text {
        // display: inline-block;
        // visibility: hidden;
        // overflow: hidden;
        // width: 0;
      }
    }
  }

  @include media-breakpoint-up($searchsorting-tablet) {
    .sorting-label {
      margin: 0 $searchsorting-tablet-label-gap 0 0;
    }

    .sorting-dropdown {
      .dropdown-text {}
    }
  }
}
