
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$searchfilter-multiselect-control-border-width: 1px !default;
$searchfilter-multiselect-control-border-color: $control !default;

.search-filter.filter-multiselect {
  .custom-checkbox {
    .custom-control-input {
      &:not(:checked) {
        ~ .custom-control-label {
          &:before {
            border-width: $searchfilter-multiselect-control-border-width;
            border-color: $searchfilter-multiselect-control-border-color;
          }
        }
      }
    }
  }
}
